<template>
  <section class="container">
    <div class="row justify-content-center">
      <div class="col-12 mt-3">
        <h3>
          <b>Slet leverandør</b>
        </h3>
      </div>
      <div class="col-auto">
        <BaseActionButton
          class="btn-delete"
          type="button"
          v-on:on-click="onSubmit"
          v-if="$can('delete', 'Supplier')"
        >
          Slet
        </BaseActionButton>
      </div>
      <div class="col-12">
        <hr />
      </div>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "SupplierSettings",
  components: {},
  data() {
    return {
      clicked: false,
      disabled: true,
    };
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
  },
  methods: {
    async onSubmit() {
      Swal.fire({
        title: "Slette leverandør?",
        text: "Vil du slette leverandøren?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ja slet!",
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            var result = await this.$store.dispatch(
              "supplierVuex/deleteSupplier",
              this.supplierId
            );
            return result;
          } catch (error) {
            console.log(error);
            Swal.showValidationMessage(`Request failed: ${error.message}`);
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.toast("Success", "Leverandøren er blevet slettet", true);
          this.$router.push({ name: "Suppliers" });
          this.clicked = false;
        }
      });
    },
  },
  computed: {
    supplierId() {
      return this.$route.params.supplierId;
    },
    company() {
      return this.$store.getters["supplierVuex/supplier"];
    },
    supplierName() {
      return this.supplier?.name ?? "";
    },
  },
};
</script>

<style></style>
